// extracted by mini-css-extract-plugin
export var content = "TwoColumn-module--content--0a737";
export var eyebrow = "TwoColumn-module--eyebrow--5cacb";
export var headingH2 = "TwoColumn-module--headingH2--f0fe6";
export var headingH3 = "TwoColumn-module--headingH3--a4113";
export var headingH4 = "TwoColumn-module--headingH4--2bb66";
export var headingH5 = "TwoColumn-module--headingH5--9dc35";
export var headingH6 = "TwoColumn-module--headingH6--35a08";
export var imageColumn = "TwoColumn-module--imageColumn--a39e3";
export var imageWrapper = "TwoColumn-module--imageWrapper--8b7cc";
export var link = "TwoColumn-module--link--c28ad";
export var list = "TwoColumn-module--list--da390";
export var opacityEnter = "TwoColumn-module--opacityEnter--8226b";
export var opacityExit = "TwoColumn-module--opacityExit--204af";
export var paragraph = "TwoColumn-module--paragraph--d235b";
export var rollDown = "TwoColumn-module--rollDown--a8f09";
export var rollUp = "TwoColumn-module--rollUp--be1bc";
export var root = "TwoColumn-module--root--fec78";
export var slideInDown = "TwoColumn-module--slideInDown--666e6";
export var slideOutUp = "TwoColumn-module--slideOutUp--3f1d5";
export var splashEnter = "TwoColumn-module--splashEnter--bcbf1";
export var splashExit = "TwoColumn-module--splashExit--7a8c4";
export var textContent = "TwoColumn-module--textContent--95994";
export var wrapper = "TwoColumn-module--wrapper--8b039";