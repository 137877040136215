import React from 'react';
import Image from '@/components/elements/Image';
import Video from '@/components/elements/Video';
import ModuleWrapper from '@/components/modules/ModuleWrapper/ModuleWrapper';
import SectionRichText from '../SectionRichText';
import type { TwoColumnTypes } from './TwoColumn.types';
import * as styles from './TwoColumn.module.scss';

export default function TwoColumn({
  body,
  background,
  eyebrow,
  image_position,
  image,
  video_url,
  className,
  top_spacing = 'md',
  bottom_spacing = 'md',
  frame = false,
  arrowLinks = false,
}: TwoColumnTypes) {
  return (
    <section className={styles.wrapper} data-background={String(background)}>
      <ModuleWrapper topSpacing={top_spacing} bottomSpacing={bottom_spacing}>
        <div className={styles.content} data-reversed-align={String(image_position === 'left')}>
          <div className={styles.textContent} data-position={String(image_position)}>
            {eyebrow && (
              <p className={styles.eyebrow} data-background={String(background)}>
                {eyebrow}
              </p>
            )}
            <SectionRichText body={body} className={className || styles} arrowLinks={arrowLinks} />
          </div>
          <div className={styles.imageWrapper} data-position={String(image_position)} data-frame={String(frame)}>
            {video_url && video_url?.trim().length ? (
              <Video video_url={video_url} />
            ) : (
              <Image className={styles.imageColumn} data={image} fluid />
            )}
          </div>
        </div>
      </ModuleWrapper>
    </section>
  );
}
